.firstView{
    background: #55b18b;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
}
.detailsCard{
    background-color:#44896c;
    margin: 10px;
    padding: 15px;
    padding-bottom: 1px
}
    .textStyle{
        margin-top: -15px;
        color: white;
    }