.checkinCard{
    background-color:  #44896c;
    padding: 10px;
    /* padding-bottom: 1px; */
}
.checkinCardSec{
    display: flex;
    background-color:  #44896c;
    padding: 10px;
    /* padding-bottom: 1px; */
}
.textStylegeustcardSec {
    margin-top: -8px;
    color: white;
}