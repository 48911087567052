.checkinCard{
    background-color:  #44896c;
    padding: 10px;
    /* padding-bottom: 1px; */
}
.mainView{
    /* height: 689px; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkinCardSec{
    display: flex;
    background-color:  #44896c;
    padding: 10px;
    /* padding-bottom: 1px; */
}
.mainView2{
    /* height: 400px; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
}