.detailsCardStyle {
    background-color: #44896c;
    /* margin: 10px; */
    padding: 15px;
    padding-bottom: 1px
}

.textStylegeustcard {
    margin-top: -13px;
    color: white;
}

.textStylegeustDetailscard {
    margin-top: -12px;
    color: #efeaea;
}
.guestdetailsCardStyle {
    background-color:  #6C757D;
    margin: 10px;
    padding: 22px;
    padding-bottom: 1px
}
.checkinstyle{
    background-color:  #207330d4 !important;
}