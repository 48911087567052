.login-show-hide-button{
    position: absolute;
    left: 345px;
    top: -2px;
    color: transparent;
    border-color: transparent;
    background-color: transparent;
}

.login-container {
    position: relative;
    width: 100%;
    background-color: #fff;
    min-height: 100vh;
    overflow: hidden;
  }
  .forms-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .signin-signup {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 75%;
    width: 50%;
    transition: 1s 0.7s ease-in-out;
    display: grid;
    grid-template-columns: 1fr;
    z-index: 5;
  }
  .signin-signup form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0rem 5rem;
    transition: all 0.2s 0.7s;
    overflow: hidden;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  .sign-up-form {
    opacity: 0;
    z-index: 1;
  }
  .sign-in-form {
    z-index: 2;
  }
  .login-header-block{
    text-align:center;
    margin-bottom: 15px;
  }
  .login-header-block img{
    width: 150px;
  }
  .login-header-block .title {
    /* font-size: 30px; */
    font-size: 20px;
    color: #000;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .input-field {
    max-width: 380px;
    width: 100%;
    background-color: #f6f6f6;
    border: 1px solid #c6c6c6;
    margin: 10px 0;
    height: 55px;
    border-radius: 55px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0 0.4rem;
    position: relative;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 8px;
  }
  .input-field i {
    text-align: center;
    line-height: 55px;
    color: #acacac;
    transition: 0.5s;
    font-size: 1.1rem;
  }
  .input-field input {
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 400;
    font-size: 15px;
    color: #333;
    font-family: 'Oswald', sans-serif;
  }
  
  .input-field input::placeholder {
    color: #aaa;
    font-weight: 400;
  }
  
  .form-group {
    display: block;
    margin-bottom: 15px;
  }
  
  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .form-group label {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    color: #303030;
  }
  .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #fa8f00;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    margin-top: -2px;
  }
  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 8px;
    width: 4px;
    height: 10px;
    border: solid #fa8f00;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .forget-text {
    font-size: 16px;
    font-weight: 400;
    margin-top:10px;
  }
  .forget-text a{
    text-decoration: none;
    transition: .5s;
  }
  .forget-text a:hover{
    color: #fa8f00;
  }
  .sign-up-form p{
    font-size: 12px;
    color: #aaa8a8;
  }
  .sign-up-form p a{
    color: #aaa8a8;
    text-decoration: none;
  }
  .sign-up-form p a:hover{
    color: #fa8f00;
  }
  .btn {
    width: 150px;
    background-color: #fa8f00;
    border: none;
    outline: none;
    height: 49px;
    border-radius: 50px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    margin: 15px 0 0;
    cursor: pointer;
    transition: 0.5s;
    font-family: 'Oswald', sans-serif;
  }
  .btn:hover {
    background-color: #000000;
  }
  .panels-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .login-container:before {
    content: "";
    position: absolute;
    height: 2000px;
    width: 2000px;
    top: -10%;
    right: 48%;
    transform: translateY(-50%);
    background:#fa8f00;
    transition: 1.8s ease-in-out;
    border-radius: 50%;
    z-index: 6;
  }
  .image {
    width: 100%;
    transition: transform 1.1s ease-in-out;
    transition-delay: 0.4s;
  }
  .panel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    text-align: center;
    z-index: 6;
  }
  .left-panel {
    pointer-events: all;
    padding: 3rem 17% 2rem 12%;
  }
  .right-panel {
    pointer-events: none;
    padding: 3rem 12% 2rem 17%;
  }
  .panel .content {
    color: #fff;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }
  .panel h3 {
    font-weight: 500;
    line-height: 1;
    font-size: 32px;
  }
  .panel p {
    font-size: 16px;
    font-weight: 400;
    margin: 10px 0 25px;
  }
  .btn.transparent {
    margin: 0;
    background: #fff;
    border: 2px solid #fff;
    color: #fa8f00;
    width: 130px;
    height: 45px;
    font-weight: 400;
    font-size: 15px;
    transition: 1s;
  }
  .btn.transparent:hover{
    background:#000;
    border:2px solid #000;
    color:#fff;
  }
  .right-panel .image,
  .right-panel .content {
    transform: translateX(800px);
  }
  
  /* ANIMATION */
  
  .login-container.sign-up-mode:before {
    transform: translate(100%, -50%);
    right: 52%;
  }
  
  .login-container.sign-up-mode .left-panel .image,
  .login-container.sign-up-mode .left-panel .content {
    transform: translateX(-800px);
  }
  
  .login-container.sign-up-mode .signin-signup {
    left: 25%;
  }
  
  .login-container.sign-up-mode form.sign-up-form {
    opacity: 1;
    z-index: 2;
  }
  
  .login-container.sign-up-mode form.sign-in-form {
    opacity: 0;
    z-index: 1;
  }
  
  .login-container.sign-up-mode .right-panel .image,
  .login-container.sign-up-mode .right-panel .content {
    transform: translateX(0%);
  }
  
  .login-container.sign-up-mode .left-panel {
    pointer-events: none;
  }
  
  .login-container.sign-up-mode .right-panel {
    pointer-events: all;
  }
  
  @media (max-width: 870px) {
    .login-container {
      min-height: 800px;
      height: 100vh;
    }
    .signin-signup {
      width: 100%;
      top: 95%;
      transform: translate(-50%, -100%);
      transition: 1s 0.8s ease-in-out;
    }
  
    .signin-signup,
    .login-container.sign-up-mode .signin-signup {
      left: 50%;
    }
  
    .panels-container {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 2fr 1fr;
    }
  
    .panel {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 2.5rem 8%;
      grid-column: 1 / 2;
    }
  
    .right-panel {
      grid-row: 3 / 4;
    }
  
    .left-panel {
      grid-row: 1 / 2;
    }
  
    .image {
      width: 200px;
      transition: transform 0.9s ease-in-out;
      transition-delay: 0.6s;
    }
  
    .panel .content {
      padding-right: 15%;
      transition: transform 0.9s ease-in-out;
      transition-delay: 0.8s;
    }
  
    .panel h3 {
      font-size: 1.2rem;
    }
  
    .panel p {
      font-size: 0.7rem;
      padding: 0.5rem 0;
    }
  
    .btn.transparent {
      width: 110px;
      height: 35px;
      font-size: 0.7rem;
    }
  
    .login-container:before {
      width: 1500px;
      height: 1500px;
      transform: translateX(-50%);
      left: 30%;
      bottom: 68%;
      right: initial;
      top: initial;
      transition: 2s ease-in-out;
    }
  
    .login-container.sign-up-mode:before {
      transform: translate(-50%, 100%);
      bottom: 32%;
      right: initial;
    }
  
    .login-container.sign-up-mode .left-panel .image,
    .login-container.sign-up-mode .left-panel .content {
      transform: translateY(-300px);
    }
  
    .login-container.sign-up-mode .right-panel .image,
    .login-container.sign-up-mode .right-panel .content {
      transform: translateY(0px);
    }
  
    .right-panel .image,
    .right-panel .content {
      transform: translateY(300px);
    }
  
    .login-container.sign-up-mode .signin-signup {
      top: 5%;
      transform: translate(-50%, 0);
    }
  }
  
  @media (max-width: 570px) {
    form {
      padding: 0 1.5rem;
    }
  
    .image {
      display: none;
    }
    .panel .content {
      padding: 0.5rem 1rem;
    }
    .login-container {
      padding: 1.5rem;
    }
  
    .login-container:before {
      bottom: 72%;
      left: 50%;
    }
  
    .login-container.sign-up-mode:before {
      bottom: 28%;
      left: 50%;
    }
  }
  @media(min-width:320px){
  .signin-signup form{
    padding: 0 20px;
  }
  .panel h3 {
    font-size: 24px;
  }
  .panel p {
    font-size: 14px;
    padding: 0;
  }
  }
  @media(min-width:480px){
  .panel h3 {
    font-size: 24px;
  }
  .panel p {
    font-size: 16px;
    padding: 0;
  }
  }
  @media(min-width:575px){
  .signin-signup form{
    padding: 0rem 5rem;
  }
  .panel h3 {
    font-size: 24px;
  }
  .panel p {
    font-size: 14px;
    padding: 0;
  }
  }

  
@media (max-width: 767px) {
    .login-show-hide-button{
        position: absolute;
        left: 280px;
        top: -2px;
        color: transparent;
        border-color: transparent;
        background-color: transparent;
    }
}

@media (max-width: 1023px) {
    .login-show-hide-button{
        position: absolute;
        left: 280px;
        top: -2px;
        color: transparent;
        border-color: transparent;
        background-color: transparent;
    }
}
@media (max-width: 667px) {
    .login-show-hide-button{
        position: absolute;
        left: 100px;
        top: -2px;
        color: transparent;
        border-color: transparent; 
        background-color: transparent;
    }
}
@media (max-width: 950px) {
    .login-show-hide-button{
        position: absolute;
        left: 283px;
        top: -2px;
        color: transparent;
        border-color: transparent;
        background-color: transparent;
    }
}


